<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="item.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    disabledAllFields
    @cancel="onClickCancel"
  >
    <template v-slot:body="{ field }">
      <p
        v-if="form[field.key].length > 0"
        class="editable-form__description custom-description"
      >
        {{ field.label }}
      </p>
      <div
        v-if="!!form[field.key]"
        class="custom-textarea"
        v-html="form[field.key]"
      ></div>
    </template>
    <template v-slot:attachments="{ field }">
      <p v-if="form[field.key].length > 0" class="editable-form__description">
        {{ field.label }}
      </p>
      <div v-if="form[field.key].length > 0" class="custom-attachments">
        <a
          v-for="item in form[field.key]"
          :key="item.id"
          class="editable-list__link"
          @click="onDownloadFile(item.id)"
        >
          {{ item.guid.fileName }}
        </a>
      </div>
    </template></sm-editable-item
  >
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'MailBoxesSingleMessage',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'MailBoxesMessages',
      pageHeader: 'Просмотр письма',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Почта',
          route: { name: 'MailBoxesMessages' },
        },
        {
          text: 'Просмотр письма',
        },
      ],
      form: {},
      customFields: [],
    };
  },

  computed: {
    ...mapState(['item']),
    fields() {
      return [
        {
          form: this.customFields,
        },
      ];
    },
  },

  created() {
    this.fillForm();
  },

  methods: {
    ...mapActions(['getItem', 'downloadFile']),
    fillForm() {
      this.getItem({ name: this.controllerName, id: this.id }).then(() => {
        const origin = this.lodash.cloneDeep(this.item.data);
        const currentFields = [];
        for (const [key, value] of Object.entries(origin)) {
          let order;
          switch (key) {
            case 'sentDate':
              order = 1;
              break;
            case 'from':
              order = 2;
              break;
            case 'subject':
              order = 3;
              break;
            case 'userName':
              order = 4;
              break;
            case 'accountName':
              order = 5;
              break;
            case 'customerName':
              order = 6;
              break;
            case 'messageGroupId':
              order = 7;
              break;
            case 'body':
              order = 8;
              break;
            default:
              order = 9;
          }
          if (key === 'attachments') {
            this.form[key] = value;
            currentFields.push({
              type: 'file',
              key,
              label: 'Вложения:',
              order,
            });
          } else if (key !== 'id' && key !== 'isReaded') {
            this.form[key] = value;
            let currentLabel;
            switch (key) {
              case 'sentDate':
                currentLabel = 'Дата получения';
                break;
              case 'from':
                currentLabel = 'От кого';
                break;
              case 'subject':
                currentLabel = 'Заголовок';
                break;
              case 'body':
                currentLabel = 'Сообщение';
                break;
              case 'userName':
                currentLabel = 'На какую почту получено';
                break;
              case 'accountName':
                currentLabel = 'Клиент';
                break;
              case 'customerName':
                currentLabel = 'Аккаунт';
                break;
              case 'messageGroupId':
                currentLabel = 'Название группы сообщений почтового ящика';
                break;
              default:
                currentLabel = key;
            }
            currentFields.push({
              type: key === 'body' ? 'textarea' : 'text',
              key,
              label: currentLabel + ':',
              order,
            });
          }
        }
        this.customFields = currentFields.sort((a, b) => {
          return a.order - b.order;
        });
      });
    },
    onDownloadFile(id) {
      this.downloadFile({
        name: this.controllerName,
        id,
        type: 'file',
      });
    },
    onClickCancel() {
      const route = 'MailBoxesMessages';
      const query = {};
      if (this.previosPageParams.previosRouteName === route) {
        query.table = JSON.stringify(
          this.previosPageParams.previosRouteQuerySort
        );
        query.filter = JSON.stringify(
          this.previosPageParams.previosRouteQueryFilter
        );
      }
      this.$router.push({ name: route, query });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-description {
  margin-bottom: 15px;
}
.custom-textarea {
  width: 100%;
  min-height: 72px;
  max-height: 100%;
  padding: 7px 12px;
  font-size: 16px;
  line-height: 1;
  border: 1px solid var(--gray);
  border-radius: 10px;
  outline: none;
  scrollbar-color: var(--gray) var(--white);
  scrollbar-width: thin;
  background-color: rgba(var(--rgb-gray), 0.2);
}
.custom-attachments {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
